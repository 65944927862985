<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <span class="right-menu-item p-style">{{robotLabel}}</span>
        <span class="right-menu-item p-style">{{mapLabel}}</span>
        <span class="right-menu-item p-style">{{taskLable}}</span>
        <sysWarn class="right-menu-item hover-effect"/>
        <search id="header-search" class="right-menu-item" />
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import {Client} from "@stomp/stompjs"
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import Search from '@/components/HeaderSearch'
import SysWarn from '@/components/SysWarn'
import { getRobot, getMap } from '@/utils/auth'

export default {
  data() {
    return {
      robotLabel: "未连接机器人",
      mapLabel: "未连接地图",
      taskLable: "机器人状态--> 查询中",
      clientMQ: null
    }
  },
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    Search,
    SysWarn
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ]),
    robotL() {
        return this.$store.getters.robotName
    },
    mapL() {
        return this.$store.getters.mapName
    },
    taskL() {
        return this.$store.getters.robotState
    },
    StateL() {
        return this.$store.getters.mqUrl
    }
  },
  watch: {
    robotL: {
      handler (nl,ol) {
        if(nl !='' && nl!= '未连接') {
          this.robotLabel = '已连接机器人--> ' +nl
        } else {
          this.robotLabel = "未连接机器人"
        }
      },
      deep: true,
      immediate: true
    },
    mapL: {
      handler (nl,ol) {
        if(nl !='' && nl!= '未连接') {
          this.mapLabel = '已连接地图--> ' +nl
        } else {
          this.mapLabel = "未连接地图"
        }
      },
      deep: true,
      immediate: true
    },
    taskL: {
      handler (nl,ol) {
        if(nl !='' && nl!= '未连接') {
          this.taskLable = '机器人状态--> ' +nl
        } else {
          this.taskLable = "机器人状态--> 查询中"
        }
      },
      deep: true,
      immediate: true
    },
    StateL: {
      handler (nl,ol) {
        if(nl !='') {
          if(this.clientMQ !== null) {
            this.clientMQ.deactivate()
            this.clientMQ = null
          }
          this.connect()
        } else {
          if(this.clientMQ !== null) {
            this.clientMQ.deactivate()
            this.clientMQ = null
          }
          this.taskLable = "机器人状态--> 查询中"
        }
      },
      deep: true,
      immediate: true
    },
  },
  beforeDestroy() {
    if(this.clientMQ !== null) {
      this.clientMQ.deactivate()
      this.clientMQ = null
    }
  },
  created() {
    // this.connect()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/index';
        })
      })
    },
    connect() {
      var that = this
      // 连接mq 的配置
      let conf = {
          brokerURL: that.$store.getters.mqUrl,
          // 登录账户的用户名和密码
          connectHeaders: {
            login: process.env.VUE_APP_BASE_RAABBITMQ_NAME,
            passcode: process.env.VUE_APP_BASE_RAABBITMQ_PASSWORD
          }
      }
      // 初始化客户端
      this.clientMQ = new Client(conf)
      // 连接成功的回调函数
      this.clientMQ.onConnect = (x) => {
        // console.log("成功---", x)
        that.getMessage()
      }
      // 连接mq
      this.clientMQ.activate()
    },
    getMessage() {
      var that = this
      let callback = function (message) {
        // console.log(JSON.parse(message.body))
        var result = JSON.parse(message.body)
        if(result.robot_sn === that.$store.getters.robotSerial) {
          if(result.job_state === 0) {
            that.$store.commit('SET_ROBOTSTATE', '待机')
          } else if(result.job_state === 1) {
            that.$store.commit('SET_ROBOTSTATE', '巡检中')
          } else if(result.job_state === 2) {
            that.$store.commit('SET_ROBOTSTATE', '任务暂停')
          } else if(result.job_state === 3) {
            that.$store.commit('SET_ROBOTSTATE', '任务终止')
          } else if(result.job_state === 4) {
            that.$store.commit('SET_ROBOTSTATE', '返回充电桩')
          }
        } else if(result.robot_sn === "" && that.$store.getters.robotState === "") {
          if(result.job_state === 0) {
            that.$store.commit('SET_ROBOTSTATE', '待机')
          } else if(result.job_state === 1) {
            that.$store.commit('SET_ROBOTSTATE', '巡检中')
          } else if(result.job_state === 2) {
            that.$store.commit('SET_ROBOTSTATE', '任务暂停')
          } else if(result.job_state === 3) {
            that.$store.commit('SET_ROBOTSTATE', '任务终止')
          } else if(result.job_state === 4) {
            that.$store.commit('SET_ROBOTSTATE', '返回充电桩')
          }
        }
      }
      let subscription = this.clientMQ.subscribe("/exchange/robotExecStatusExchange", callback);
    }
  }
}
</script>

<style lang="less" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      // vertical-align: text-bottom;
      vertical-align: middle;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }

    }

    .p-style {
      background-color: #11a983;
      color: #fff;
      line-height:20px;
      font-size:15px;
      height: 40px;
      width: 150px;
      margin: 0 8px;
      text-align: center;
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
